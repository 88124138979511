/*month-table*/

#month-table td:nth-child(2),
#month-table tr:nth-child(1) th:nth-child(2),
#month-table tr:nth-child(2) th:nth-child(1) {
    border-left: #f0f0f0 3px solid;
}

#month-table td:nth-child(4),
#month-table tr:nth-child(1) th:nth-child(3),
#month-table tr:nth-child(2) th:nth-child(3) {
    border-left: #f0f0f0 3px solid;
}

#month-table td:nth-child(6),
#month-table tr:nth-child(1) th:nth-child(4),
#month-table tr:nth-child(2) th:nth-child(5) {
    border-left: #f0f0f0 3px solid;
}

#month-table td:nth-child(8),
#month-table tr:nth-child(1) th:nth-child(5),
#month-table tr:nth-child(2) th:nth-child(7) {
    border-left: #f0f0f0 3px solid;
}

#month-table td:nth-child(10),
#month-table tr:nth-child(1) th:nth-child(6),
#month-table tr:nth-child(2) th:nth-child(9) {
    border-left: #f0f0f0 3px solid;
}

.recharts-tooltip-label {
    display: none;
}

/*version-table*/

#version-table td:nth-child(3),
#version-table tr:nth-child(1) th:nth-child(3),
#version-table tr:nth-child(2) th:nth-child(1) {
    border-left: #f0f0f0 3px solid;
}

#version-table td:nth-child(5),
#version-table tr:nth-child(1) th:nth-child(4),
#version-table tr:nth-child(2) th:nth-child(3) {
    border-left: #f0f0f0 3px solid;
}

#version-table td:nth-child(7),
#version-table tr:nth-child(1) th:nth-child(5),
#version-table tr:nth-child(2) th:nth-child(5) {
    border-left: #f0f0f0 3px solid;
}

#version-table td:nth-child(9),
#version-table tr:nth-child(1) th:nth-child(6),
#version-table tr:nth-child(2) th:nth-child(7) {
    border-left: #f0f0f0 3px solid;
}

#version-table td:nth-child(11),
#version-table tr:nth-child(1) th:nth-child(7),
#version-table tr:nth-child(2) th:nth-child(9) {
    border-left: #f0f0f0 3px solid;
}
