@import '../../assets/css/App.css';

.logo-img {
  height: 100%;
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}


.ant-image {
  vertical-align: middle;
}

#repository-table td:nth-child(3)
/* #repository-table tr:nth-child(1) th:nth-child(2), */
/* #repository-table tr:nth-child(2) th:nth-child(3)  */
{
  border-left: #f0f0f0 3px solid;
}

#repository-table td:nth-child(9)
/* #repository-table tr:nth-child(1) th:nth-child(4), */
/* #repository-table tr:nth-child(2) th:nth-child(10)  */
{
  border-left: #f0f0f0 3px solid;
}

#repository-table td:nth-child(11)
/* #repository-table tr:nth-child(1) th:nth-child(4), */
/* #repository-table tr:nth-child(2) th:nth-child(10) */
{ 
  border-left: #f0f0f0 3px solid;
}
.disable-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}