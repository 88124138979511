#help ol {
  counter-reset: item
}

#help li {
  display: block
}
#help  p{
  font-size: 14px;
  text-align: justify;
}
#help ol>li:before {
  content: counters(item, ".") " ";
  counter-increment: item;
  margin-left: -20px;

}
#help ol>li {
  font-size: 15px;
}
#help ul>li {
  font-size: 14px;
}
#help ul>li:before{
  content:'• '
}
.sub-item {
  padding-left: 20px;
}